import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from "react-bootstrap"
import styled from "styled-components"
import images from '../images';
import ScrollAnimation from "react-animate-on-scroll";
import "../animation.css"
const DivC = styled.div`
position:relative;
background-color: #FBFBFB;
padding:90px 0px 10px 0px;

overflow: hidden;
min-height:100vh;
top:0px;


.container{
    /* position:relative */
}
.title{
    position:sticky;
    top:80px;
}

`
const OurProjects = () => {



    return <DivC id="projects">
        
        <Container>
            <Row>
               
                <Col>
                    <h1 className='title'>Some of our work.</h1>
                </Col>
            </Row>
        </Container>
        
    </DivC>

}


export default OurProjects