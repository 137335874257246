import React from 'react'
import styled from "styled-components"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
const DivC = styled.div`

  --text-color: #FFF;
  --shadow-color: hsla(210, 40%, 52%, .4);
  --btn-color: #fe0002;
  --bg-color: #f51f1f;


* {
  box-sizing: border-box;
}



button {
   
  position:relative;
  padding: 10px 20px;  
  border: none;
  background: none;
  cursor: pointer;
  
 
  font-weight: 400;
  font-size: 24px;  
  color: var(--text-color);
  
  background-color: var(--btn-color);
  border-radius:30px; 
  z-index: 0;  
  overflow: hidden;   
}

button:focus {
  outline-color: transparent;
}

.right::after, button::after {
    content:  'Get Started';
  display: block;
  position: absolute;
  white-space: nowrap;
  
  padding: 40px 40px;
  pointer-events:none;
}


button::after{
  font-weight: 100!important;
  top: -30px;
  left: -20px;
} 

.right, .left {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.right {
  left: 66%;
}
.left {
  right: 66%;
}
.right::after {
  top: -30px;
  left: calc(-66% - 20px);
  
  background-color: var(--bg-color);
  color:transparent;
  transition: transform .4s ease-out;
  transform: translate(0, -90%) rotate(0deg)
}

button:hover .right::after {
  transform: translate(0, -47%) rotate(0deg)
}

button .right:hover::after {
  transform: translate(0, -50%) rotate(-7deg)
}

button .left:hover ~ .right::after {
  transform: translate(0, -50%) rotate(7deg)
}

/* bubbles */
button::before {
  content: '';
  pointer-events: none;
  opacity: .6;
  background:
    radial-gradient(circle at 20% 35%,  transparent 0,  transparent 2px, var(--text-color) 3px, var(--text-color) 4px, transparent 4px),
    radial-gradient(circle at 75% 44%, transparent 0,  transparent 2px, var(--text-color) 3px, var(--text-color) 4px, transparent 4px),
    radial-gradient(circle at 46% 52%, transparent 0, transparent 4px, var(--text-color) 5px, var(--text-color) 6px, transparent 6px);

  width: 100%;
  height: 300%;
  top: 0;
  left: 0;
  position: absolute;
  animation: bubbles 5s linear infinite both;
}

@keyframes bubbles {
  from {
    transform: translate();
  }
  to {
    transform: translate(0, -66.666%);
  }
}
`
const GetStartedButton = () => {




  return <DivC>
    <button >
        <div className="left"></div>
        Get Started
        <div className="right"></div>
    </button>
  </DivC>

}


export default GetStartedButton