import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from "react-bootstrap"
import styled from "styled-components"
import images from '../images';
import IconButton from '@mui/material/IconButton';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa"
import {HiOutlineLocationMarker,HiPhone,HiOutlineMail} from "react-icons/hi"
const DivC = styled.div`
position:relative;
background-color: #FAFAFA;
padding:30px 20px 10px 20px;
padding-top:80px;

color:#333;
.social-media{
    width:100%;
    max-width: 600px;
    font-size: 32px;
    
}
a{
    color:#666;transition:color .3s;
}
a:hover{
    color:var(--main_color);
}
.list-group-item{
    background-color:transparent
}
.list-group-item{
    border:none;
    color:#444
}
.list-group-item svg{
    font-size: 24px!important;
}
a{
    text-decoration: none;
}
h1{
    font-size:3em;
    font-weight: 700;
}
`
const Footer = () => {



    return <DivC id="contact_us">
        <div className='d-flex justify-content-center'>
            <div className="social-media d-flex justify-content-around">
                <a href="" target="_blank">
                    <FaFacebook />
                </a>
                <a href="" target="_blank">
                    <FaTwitter />
                </a>
                <a href="" target="_blank">
                    <FaLinkedin />
                </a>
                <a href="" target="_blank">
                    <FaInstagram />
                </a>
            </div>
        </div>
        <Container className="mt-4 pt-4 pb-0">
            <Row>
                <Col lg={6} md={4} sm={12}>
                    <h1>Get in touch!</h1>
                    <p>By subscribing to us, you will receive our new offers exclusively via your personal email</p>

                </Col>
                <Col lg={3} md={4} sm={12}>
                    <h4>Support</h4>
                    
                    
                   
                    <ul class="list-group">
                        <li class="list-group-item d-flex align-items-center">
                           <Link to={"#"}>Contact</Link>
                        </li>
                        <li class="list-group-item d-flex align-items-center">
                           <Link to={"#"}>Privacy Policy</Link>
                        </li>
                        <li class="list-group-item d-flex align-items-center">
                            <Link to={"#"}>Terms of Use</Link>
                        </li>
                    </ul>
                </Col>
                <Col lg={3} md={4} sm={12}>
                    <h4>Contact us</h4>
                    <ul class="list-group">
                        <li class="list-group-item d-flex align-items-center">
                            <HiOutlineLocationMarker className='me-3'/>
                            Alger,Algeria
                        </li>
                        <li class="list-group-item d-flex align-items-center">
                        <HiPhone className='me-3'/>
                            <div className='d-flex flex-column'>
                                <span>(+213) 770 17 17 95</span>
                                <span>(+213) 770 24 24 45</span>
                                <span>(+213) 540 71 23 93</span>
                            </div>
                        </li>
                        <li class="list-group-item d-flex align-items-center">
                        <HiOutlineMail className='me-3'/>
                        contact@devgat.net
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className='pb-0'>
                <Col>
                    <hr />
                    <p className='p-0 text-center text-secondary'
                        style={{ fontSize: "14px" }}>© 2022. All rights reserved by DevGate studio.</p>
                </Col>
            </Row>
        </Container>
    </DivC>

}


export default Footer