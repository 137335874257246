import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import styled from "styled-components"
import images from '../images';
import IconButton from '@mui/material/IconButton';
import { GrClose, GrMenu } from "react-icons/gr"
const DivC = styled.div`

.navbar{
  height:80px!important;
  margin-top:40px;
  
  min-height:80px!important;
  transition:padding .3s;
  display:flex;
  justify-content: center;

}
.navbar-inner{
  
  /* margin-left:50%; */
  /* transform:translateX(-50%); */
  /* max-width: 1300px; */
  width: 100%;

}
@media screen and (min-width: 992px){
  .navbar-inner{
    max-width: 960px;
  }
}
@media screen and (min-width: 1200px){
  .navbar-inner{
    max-width: 1140px;
  }
}
@media screen and (min-width: 1400px){
  .navbar-inner{
    max-width: 1320px;
  }
}
/* @media screen and (min-width: 768px){
  .navbar-inner{
    max-width: 720px;
  }
} */




.fix-navbar{
  margin-top:0px;
  position: fixed!important;
  top:0;
  left:0%;
  right:0;
  background:#FCFCFC;
  z-index: 100;
  box-shadow: 0px 0px 8px 0px #3333;
}

@media screen and (max-width: 1070px){
  .navbar{padding-left: 40px;
  padding-right:40px;
  margin-top:0px;}
}
@media screen and (max-width: 618px){
  .navbar{padding-left: 12px;
  padding-right:12px;}
}
.logo{
  height:50px;
  
  /* overflow: hidden; */
  font-size: 24px;
}
.logo img{
  border-radius:50%;
  width:50px;
  height:50px
}
.logo .path{
  width:44px;
  height:40px;
  position: relative;
  border:10px solid var(--main_color);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left-color: transparent;
}
.logo .path:after,.logo .path:before{
  content: "";
  position: absolute;
  height: 20px;
  width:10px;
  background-color: var(--main_color);
  
}
.logo .path:after{
  top:-10px;
  left:-10px;
  transform:skewX(30deg);
  transform-origin: 0% 0%;
}
.logo .path:before{
  bottom:-10px;
  left:-10px;
  transform:skewX(-30deg);
  transform-origin: 0% 100%;
}
a{
  text-decoration: none;
  color: #444;
  /* background-color: #AAA; */
  padding:6px 12px;
  position:relative;
  overflow: hidden;
}
a:after{
  position:absolute;
  content:"";
  bottom:0;
  left:0;
  height:2px;
  border-radius:2px;
  background-color:var(--main_color);
  transition:all .5s;
  width:0;opacity:0;
}
a:hover{
  color:var(--main_color);
}
a:hover:after{
  width:100%;opacity:1;
}
.exit-menu{
  display:none;
}
.btn-menu{
  color:#333;
  display:none;
}
@media screen and (min-width: 948px){
  .list-nav{display:flex!important}
}
@media screen and (max-width: 948px){
  
  .list-nav{
    position: fixed;
    flex-direction: column!important;
    top:0px;
    right:0px;
    bottom:0;
    
    width: 90%;
    max-width:400px;
    min-height:100%;
    background-color: #FFF;
    
    box-shadow:0px 0px 6px 0px #0002;
    display:none;
    z-index: 100;
    animation:dm .5s;
  
  }
  .an-img{
    display:initial;
  }
  .list-nav a{
    display:block;
    padding:16px 22px;
    border-bottom:1px solid #AAA3;
  }
  .exit-menu{
    display:flex
  }
  .btn-menu{
    display:flex;
  }
}
@keyframes dm{
  from{
    transform:translateX(120%);
  }
  to{
    transform:translateX(0%);
  }
}
.db-flex{
  display:flex;
}
.an-img{
    position: absolute;
    top:0;
    width:100%;
    left:0;
    transform: rotate(180deg);

}

`
const Navbar = () => {
  const [open, setOpen] = useState(false);


  const [fixed, setFixed] = useState(false);

  const stickNavbar = () => {

    setFixed(window.scrollY > 200)
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);


  return <DivC >
    <div className={`navbar  ${fixed ? "fix-navbar" : ""}`}>
      <div className="d-flex align-items-center navbar-inner">
      <Link to={"/"}>
        <div className="logo d-flex align-items-center me-4">
          {/* <img src={images.logo} alt="" className="me-1" /> */}
          <div className='path me-1'></div>

          <div className='d-flex flex-column' style={{ textTransform: "uppercase" }} >
            <span style={{ lineHeight: "1" }}>
              <strong>Dev</strong>gate
            </span>
            <span style={{ fontSize: "12px", hight: "100%" }}>Studio</span>
          </div>

        </div>
      </Link>
      <div className={`list-nav  align-items-center justify-content-end ps-4 pe-4 flex-grow-1`} style={{ display: open ? "block" : "none" }}>
        <div className="exit-menu  justify-content-end p-3 mb-3">

          <IconButton size="large" onClick={() => setOpen(false)} sx={{position:"relative"}}>
            <GrClose sx={{color:"#FFF!important"}} />
          </IconButton>
          
        </div>
        <a className="me-3" href="#">Home</a>
        <a className="me-3" href="#services">Services</a>
        <a className="me-3" href="#about_us">About us</a>
        <a className="me-3" href="#pricing">Pricing</a>
        <a className="me-3" href="#projects">Projects</a>
        <a className="" href="#contact_us">Contact us</a>
        
      </div>

      <div className=" flex-grow-1 justify-content-end btn-menu">
        <IconButton size="large" onClick={() => setOpen(true)}>
          <GrMenu />
        </IconButton>
      </div>
      </div>
    </div>

  </DivC>

}


export default Navbar