import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from "react-bootstrap"
import styled from "styled-components"
import images from '../images';
import IconButton from '@mui/material/IconButton';
import ScrollAnimation from "react-animate-on-scroll";

const DivC = styled.div`
position:relative;
background-color: #FEFEFE;
height:100%;
overflow: hidden;
padding:20px 18px;
border-radius:5px;
box-shadow: 0px 0px 8px 0px #0001;
color: #444;;

.image{
    height:50px;
    width:50px;
    border-radius:50%;
    overflow:hidden
}
.image img{
    width: 100%;height:auto;
}
.info h5{
    font-size: 16px;
}
.info p{
    font-size: 12px;
    color:#666;
    padding:0;margin:0;
}

`
const Card = (props) => {



    return <DivC className=" d-flex flex-column ">
        <p className="desc flex-grow-1">
            {props.desc}
        </p>
        <div className='d-flex  align-items-center mt-3'>
            <div className="image">
                <img src={props.src} alt="" />
            </div>
            <div className='info ms-2'>
                <h5 className="m-0 p-0">{props.name}</h5>
                <p className="m-0 p-0">{props.country}</p>
            </div>
        </div>
    </DivC>

}


export default Card