import { useRef } from 'react'
import styled from "styled-components"
import Typography from '@mui/material/Typography';

import { Col, Row, Container } from "react-bootstrap"
import images from "../images/"
import OurServiceCard from './OurServiceCard';
import { VscPlayCircle } from "react-icons/vsc"

import { AnimationOnScroll } from "react-animation-on-scroll";
import { IoVideocamOutline, IoCubeOutline } from "react-icons/io5"
import { TbTriangleInverted } from "react-icons/tb"
import { AiOutlineDollar } from "react-icons/ai"
import ScrollAnimation from "react-animate-on-scroll";
import "../animation.css"

const DivC = styled.div`
padding: 30px;
padding-top:80px;
min-height:100vh;
position:relative;
overflow: hidden;
background-color: #fe0002;
/* position: sticky; */
top:0px;
:after,:before{
    content:"";
    position:absolute;
    top:80px;
    height: 200px;
    width:46%;
    background-color:#FFF2;
    z-index: 0;
}
:after{
    left:4%;
    transform-origin: 0 0;
    transform:skewY(35deg);
}
:before{
    right:4%;
    transform-origin: 100% 0;
    transform:skewY(-35deg);
}


.icon{
    color: #fe0002;
    font-size: 4em;
    font-weight: 200!important;
}

.text{
    font-size:1.6em;
}
.icon path{
    /* stroke-width: 20; */
}
.container{
    position: relative;
}
@media screen and (max-width: 768px){
  .ds{
   justify-content: center!important;
  }
 
    .arrow{
        height:40px
    }
    .icon{
    font-size: 3em;

}
.text{
    font-size:1.3em;
}

.cs{
    height:120px
}
}
.ds{
    display:flex
}


`
function Section1() {

    return (
        <>
        <DivC className=" d-flex flex-column " id="services">
            
            <div className="d-flex justify-content-center arrow">
                <img src={images.arrow_b} alt="" />
            </div>

            <h1 className='title text-light text-center '>Our services</h1>

            <div className="flex-grow-1 d-flex align-items-center" >

                <ScrollAnimation
                    className="flex-grow-1 d-flex align-items-center" 
                    animateIn="an_sc"
                >
                    <Container className='p-0 m-0' style={{width:"100%!important",maxWidth:"100%"}}>
                        <Row >
                            <Col  sm={6} xs={12} className="p-0 justify-content-end ds ds-tl">

                                <OurServiceCard
                                    icon={<IoVideocamOutline className="icon" />}
                                    text={"Motion video design"}
                                />
                            </Col>
                            <Col xs={12} sm={6} className="p-0 justify-content-start ds ds-tr">

                                <OurServiceCard
                                    icon={<TbTriangleInverted className="icon" />}
                                    text={"Brand design"}
                                />

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className="p-0 justify-content-end ds ds-bl">

                                <OurServiceCard
                                    icon={<AiOutlineDollar className="icon" />}
                                    text={"Digital marketing service"}
                                />

                            </Col>
                            <Col xs={12} sm={6} className="p-0  justify-content-start ds ds-br">

                                <OurServiceCard
                                    icon={<IoCubeOutline className="icon" />}
                                    text={"Product packaging design"}
                                />

                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </div>
        </DivC>
        </>
    )
}

export default Section1