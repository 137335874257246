import React from 'react'
import styled from "styled-components"
import Typography from '@mui/material/Typography';

import { Col, Row, Container } from "react-bootstrap"
import images from "../images/"

const DivC = styled.div`
padding:12px 20px;
border-radius:10px;
height:160px;
max-width: 400px;
width:100%;
box-shadow: 0px 0px 4px 0px #0004;
z-index: 5;

.icon,.text{
    height:100%
}
.icon svg{
    
}
.text{
    color:#222;
    font-size:1.9em;
    font-weight: 200!important;
}

`
function OurServiceCard(props) {
    return (
        <DivC className="d-flex m-2 bg-light cs" >
           <div className=' d-flex align-items-center me-2'>
                {props.icon}
           </div>
           <div className='text d-flex align-items-center justify-content-center flex-grow-1 text-center'>
                {props.text}
           </div>
        </DivC>
    )
}

export default OurServiceCard