import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`

:root {
  --main_color: #fe0002;
  --black_color:#111;
  --text_color: #333;
  --gray_color: #666;
  --white: #FEFEFE;
}

.title{
  font-size: 3.5em;
}
.desc_title{
  font-size:1.1em;
  font-weight: 300;
  color: var( --gray-color);
}
.text-main{
  color:var(--main-color);
}
.bg-main{
  background-color: var(--main-color);
}
@media screen and (max-width: 990px){
  .title,.desc_title{
    text-align:center;
    width: 100%;
  }
  .title{
    font-size: 2.5em;
  }
  

}
`
export default GlobalStyle;