import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from "react-bootstrap"
import styled from "styled-components"
import images from '../images';
import IconButton from '@mui/material/IconButton';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa"
import { HiOutlineLocationMarker, HiPhone, HiOutlineMail } from "react-icons/hi"
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import "../animation.css"
const DivC = styled.div`
position:relative;
background-color: #FEFEFE;
padding:90px 0px 10px 0px;
/* position: sticky; */
overflow: hidden;
top:0px;
min-height:100vh;
.img{
    width:100%;
    height:auto;
    overflow: hidden;
    border-radius:30px;
    max-width: 450px;
    box-shadow: 0px 0px 4px 0px #0004 inset;
}
.img img{
    width: 100%;
    height:auto;
}

.container {
    position:relative;
}




`
const AboutUs = () => {



    return <>
    
    
    <DivC id="about_us">
    
        <Container>
            <Row>
                <Col sm={12} lg={6} className="d-flex  align-items-center">
                <ScrollAnimation
                        animateIn="animate__bounceInLeft"
                    // animateOut="animate__bounceOutLeft"
                    >
                    <div>
                        <h1 className="title ">About our team</h1>
                        <p className="desc_title ">We're a great company and we do great things. You can say that with our wonderful services. We're here for you. We make websites, apps, other software, basically everything. We design brands and create everything necessary to manage and enhance your business.</p>
                    </div>
                    </ScrollAnimation>

                </Col>
                <Col sm={12} lg={6} >
                    <ScrollAnimation
                        animateIn="animate__bounceInRight"
                    // animateOut="animate__bounceOutLeft"
                    >
                        <div className="img">
                            <img src={images.about_img} alt="" />
                        </div>
                    </ScrollAnimation>
                </Col>
            </Row>
        </Container>

        
        
       
    </DivC>
    </> 

}


export default AboutUs