

import styled from "styled-components"
const DivC = styled.div`
position: absolute;
top:0;
left:0;
width:100%;
height:100%;

.ball,.ball2{
  position:absolute;
  width:32px;
  height:32px;
  animation:rt 60s  linear infinite;
  background: radial-gradient(#F005,transparent,transparent);
  background-clip: padding-box;
 
  border-radius:50%;
}
.ball2{
  width:60px;
  height:60px;
  animation:an2 20s  linear infinite;
}

@keyframes rt{
  from{

  }
}
@keyframes rt{
  from{
    bottom:10%;
    left:10%;
  }
  10%{
    bottom:50%;
    left:50%;
  }
  20%{
    bottom:90%;
    left:10%;
  }
  40%{
    bottom:90%;
    left:50%;
  }
  60%{
    bottom:50%;
    left:90%;
  }
  80%{
    bottom:10%;
    left:50%;
  }
  to{
    bottom:10%;
    left:10%;
  }
}



`

const Animation = () => {
   
    return <DivC>
        {/* <div className="ball">
          
        </div> */}
    </DivC>
}
export default Animation