import React from 'react'
import Box from '@mui/material/Box';
import Navbar from '../Navbar';
import Animation from './Animation';
import styled from "styled-components"
import Section1 from './Section1';
import Footer from '../Footer';
import AboutUs from '../AboutUs';
import { Col, Row, Container } from "react-bootstrap"
import images from "../images/"
import { MdDoubleArrow } from "react-icons/md"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import GetStartedButton from './button';
import ClientsSection from '../ClientsSection';
import OurProjects from '../OurProjects';
import Charts from './Charts';
const DivC = styled.div`
height:100vh;
position:relative;
background-color: #FEFEFE;
.up{
  position: relative;
  height: 100%;
  z-index: 10;
}
.left-img{
  max-width: 100%;
  height:auto;
  max-height: 100%;
}

.get-started{
  background-color:#fe0002;
  font-size:18px;
  padding:12px 24px!important;
  color:#FFF;
}
@media screen and (max-width: 990px){
  
  .header-title {
    padding:65px 20px 10px 20px
  }
  .get-started{
    margin: auto;
  }
  
}
`
const DevgateStudio = () => {




  return <>
    <DivC>

      <Animation />
      <div className="up d-flex flex-column">
        <Navbar />
        <div className='d-flex align-items-center flex-grow-1'>
          <Container>
            <Row >
              <Col sm={12} md={12} lg={6} className='d-flex flex-wrap align-content-center header-title'>
                <h1 className='title'>
                  Branding and branding design service.
                </h1>
                <p className='desc_title'>We create designs for your apps, and great brands for your business.</p>

                <button className="btn get-started">Get Started</button>
              </Col>
              <Col sm={12} md={12} lg={6} className="d-flex justify-content-center">
                <img src={images.right_image} className="left-img" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </DivC>
    <Section1 />
    <AboutUs />
    <OurProjects/>
    
    <Charts/>

    <ClientsSection/>
    <Footer/>
  </>

}


export default DevgateStudio