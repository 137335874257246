import { useRef } from 'react'
import styled from "styled-components"
import Typography from '@mui/material/Typography';

import { Col, Row, Container } from "react-bootstrap"
import images from "../images/"
import CountUp from 'react-countup';




const DivC = styled.div`

padding :34px 0px;
background-color:#fe0002;
position:sticky;top:80px;
width:100%!important;
max-width:100%!important;

h1,p{
    margin:0;padding:0;color:#FFF;
    text-align: center;
}
h1{
    font-size: 3em;
}
.border-end{
    border-color:#FFF9!important
}

`
function Charts() {

    return (
        <>
        <DivC className="container  m-0" >
            <Row className="p-0 m-0">
                <Col className="d-flex flex-column p-1" md={3} sm={6} xs={12}>
                    <h1><CountUp end={10} /></h1>
                    <p>Years of Experience</p>
                </Col>
                <Col className="d-flex flex-column p-1" md={3} sm={6} xs={12}>
                    <h1>33</h1>
                    <p>Total Pages</p>
                </Col>
                <Col className="d-flex flex-column p-1" md={3} sm={6} xs={12}>
                    <h1>12</h1>
                    <p>Header Style</p>
                </Col>
                <Col className="d-flex flex-column p-1" md={3} sm={6} xs={12}>
                    <h1>+80</h1>
                    <p>Custom Addons</p>
                </Col>
            </Row>
        </DivC>
        </>
    )
}

export default Charts