import right_image from "./right-img.png"
import logo from "./logo.jpg"
import about_img from "./about-img.jpg"
import img1 from "./im1.jpg"
import img2 from "./im2.jpg"
import img3 from "./im3.jpg"
import img4 from "./im4.jpg"
import arrow_b from "./arrow-b.svg"
import animate_svg from "./animate-svg.svg"
const images={
    right_image,logo,arrow_b,about_img,
    img1,img2,img3,img4,animate_svg
}

export default images;