
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom';
import DevgateStudio from './Components/DevgateStudio';
import GlobalStyle from "./Components/style"


const theme = createTheme({
  palette: {
   
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <Router>
          <Routes> 
            <Route path="/" element={<DevgateStudio/>}/> 
          </Routes>
        </Router>
    
    </ThemeProvider>
  );
}

export default App;
